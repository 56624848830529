@import "yona-variables";

$focus-ring-color: map-get($theme-colors, "primary-25" );
$foucs-ring-blur: 100;

$form-check-input-checked-bg-color: map-get($theme-colors, "primary" ); 

$input-focus-border-color: map-get($theme-colors, "primary-75");
$form-switch-color: map-get($theme-colors, "primary");

.dropdown{
    width: 100%;
    height: 100%;

    .dropdown-toggle{
      overflow: auto;
      border: none;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      text-align: start;
  
      &::after {
        display: none;
      }
  
      &:hover {
        background-color: map-get($theme-colors, "primary-75" ) !important;
        color: white !important;
        * {
            color: white;
        }
      }
  
      &:focus {
        background-color: map-get($theme-colors, "primary-75" ) !important;
        color: white !important;
        * {
            color: white;
        }
      }

      &:active {
        background-color: map-get($theme-colors, "primary-75") !important;
        color: white !important;
        * {
            color: white;
        }
      }
    }

    .dropdown-menu{
        padding: 4px 0px;
    }

    .dropdown-item {
        background-color: #ffffff;
        font-size: 16px;
        padding: 4px;
    
        &.active {
          background-color: map-get($theme-colors, "primary" );
          color: white;
        }
      }

    .dropdown-toggle.show{
      background-color: white;
      color:black;
      border-bottom: none;
    }
  
    .dropdown-toggle:hover{
      background-color: white;
      color: black;
    }
  }
  