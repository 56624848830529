// COLORS
$theme-colors: (
  "primary-100": #8785FF,
  "primary": #8785FF,

  "primary-75": #A5A3FF,
  "primary-50": #D2D1FF,
  "primary-25": #E1E1FF,
  "fullwhite": #FFFFFF,

  "offwhite": #F9F9FB,
  "white": #F9F9FB,

  "fullblack": #171717,

  "offblack": #222222,
  "dark": #222222,

  "darkgrey": #333333,
  "mediumgreydark": #686868,
  "mediumgreylight": #D6D6D6,

  "mediumgrey": #B3B1B1,
  "disabled": #B3B1B1,
  
  "lightred": #FFD2D7,
  "warning": #FFD2D7,

  "fullred": #BE0E23,
  "danger": #BE0E23,

  "lightgreen": #D0F5D0,

  "fullgreen": #78E378,
  "success": #78E378,

  "transparent": transparent,

  //no info, secondary color
);

$prefix: "bs-";


// TEXT
$text: map-get($theme-colors, "fullblack" );
$link: map-get($theme-colors, "primary-100");
$title-family: false;
$button-family: false;

$font-family-sans-serif: "Poppins", BlinkMacSystemFont, -apple-system, "Roboto", "Segoe UI",  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$font-family-serif: "Poppins";
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;

// BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$default: 4;
$spacer: 8px;
$spacers: ();
@for $i from 0 through 100 {
    $spacers: map-merge($spacers, ($i: #{$default * $i }px));
}

$btn-close-color: $text;
$btn-close-opacity: 1;
$form-range-track-cursor: pointer;