@import "yona-variables";

$input-btn-font-family: $font-family-sans-serif;
$btn-font-weight: 600;

$input-btn-padding-y-sm: 4px;
$input-btn-padding-x-sm: 16px;
$input-btn-font-size-sm: 12px;

$input-btn-padding-y: 8px;
$input-btn-padding-x: 32px;
$input-btn-font-size: 16px;

$input-btn-padding-y-lg: 16px;
$input-btn-padding-x-lg: 64px;  
$input-btn-font-size-lg: 20px;



.btn{
   
    &:active {
        transform: scale(0.8);
        i{
            color: white;
        }   
    }

    &:hover{
        &[class*="-dark"]{
            background-color: map-get($theme-colors , "dark" );
        }

        &:not([class*="-dark"]){
            opacity: 0.75;
            color: white;
        }
    }

    &-primary{
        color: white;

        &:active, 
        &:focus-visible {
            color: white !important;
        }
    }



    &:disabled,
    &.disabled {
        &[class*="outline"]{
            color: map-get($theme-colors, "disabled" );
            border: 1px solid map-get($theme-colors, "disabled" );
        }

        &:not([class*="outline"]){
            color: white;
            background-color: map-get($theme-colors, "disabled" );
            border: none;
        }  
    }

    &.shiny{
        position: relative;
        border: none;
        cursor: pointer;
        overflow: hidden;

        &::after{
            content: "";
            position: absolute;
            top: calc(var(--y, 0) * 1px - 50px);
            left: calc(var(--x, 0) * 1px - 50px);
            width: 100px;
            height: 100px;
            background: radial-gradient(rgba(255, 255, 255, 0.6), #242e3d00 80%);
            opacity: 0;
            transition: opacity 0.2s;
        }

        &:hover::after{
            opacity: 0.4;
        }
    }

    &:not([class*="-icon-"]){
        i{
            margin-right: $spacer;
        }
    }

    &[class*="-icon-"]{
        padding: 0px;
        margin: 0px;  

        &.btn-icon{
            &-round {
                border-radius: 100%;
            }

            &-corner {
                border-radius: 4px;
            }

            &-sm {
                height: 32px;
                width: 32px;
                i{
                    font-size: 20px;
                } 
            }
    
            &-md {
                height: 40px;
                width: 40px;
                i{
                    font-size: 24px;
                }
            }
    
            &-lg {
                height: 48px;
                width: 48px;
                i{
                    font-size: 28px;
                }
            }
        }
    }
}




// .btn:disabled, .btn[disabled], button:disabled{
//     color: white;
//     background-color: map-get($theme-colors, "mediumgrey" );
// }