@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import "yona-variables";
@import "modal";
@import "input";
@import "icons";
@import "typography";
@import "buttons";

// IMPORT BOOTSTRAP
@import "~bootstrap/scss/bootstrap";


//double import 
@import "buttons";

$tooltip-bg: map-get($theme-colors, "offblack" );
// $tooltip-padding-y: $spacer;
// $tooltip-padding-x: 80px;




// SEE DOCS FOR MORE:
// https://bit.ly/2sgFMdb


.grow-shrink-animation{
  transition-property: transform;
  transition-duration: 2s;
  animation-name: grow;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes grow{
  0% {transform: scale(1)}
  50% {transform: scale(1.2)}
  100% {transform: scale(1)}
}

hr {
  border-top: 1px solid black;
  opacity: 1;
}


.accordion {
  .accordion-item{
    border-radius: 0px;
    border-bottom: 1px solid black;
    border-right: none;
    border-left: none;
  }

  .accordion-header{
  display: flex;

  button{
    border: none;
    background-color: transparent;
    font-size: 22px;
  }
}
}

.yona-input{
  border: none ;
  border-bottom: 1px solid black;
  border-radius: 0px;
  padding-left: 0px;
  background-color: transparent;

  &-default{
    border: 1px solid black;
    border-radius: 5px;
    background-color: transparent;
  }
}

.yona-input[disabled]{
  background: repeating-linear-gradient(
    -45deg,
    #17171710,
    #17171710 10px,
    transparent 10px,
    transparent 20px
  );

  cursor: not-allowed;
}

.yona-input:focus{
  background-color: transparent;
}

// .invalid-input{
//   color: map-get($theme-colors, "fullred") ;
//   font-size: 14px;
//   margin-top: 4px;
// }

.requiredInfo {
  input:placeholder-shown{
    border-bottom: 2px red solid;
  }
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border: 0;
  background-image: url("../../public/img/yona_logo_navbar_black.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}

input[type=range]::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border: 0;
  border-radius: 0px;
  background-color: transparent;
  background-image: url("../../public/img/yona_logo_navbar_black.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}

input[type=range]::-ms-thumb {
  width: 24px;
  height: 24px;
  border: 0;
  border-radius: 0px;
  background-color: transparent;
  background-image: url("../../public/img/yona_logo_navbar_black.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}


// IMPORT FONT AWESOME
@import url("https://use.fontawesome.com/releases/v6.2.0/css/all.css");

#root {
  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }
}

