$modal-sm: 200px;
$modal-md: 611px;
$modal-lg: 800px;
$modal-xl: 1148px;


.modal-content{   
    background-color: rgba(255, 255, 255, 1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 4px;
    border: 1px solid #171717;

    .modal-header{
        border: 0;

        .btn-close{
            align-self: flex-end;
        }
    }

    .modal-body{
        padding: 0px 24px;
    }

    .modal-footer{
        border-top: 0px;
    }
}


