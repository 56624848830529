@import "yona-variables";
@import "buttons";

@font-face {
  font-family: 'yona-icons';
  src:  url('fonts/yona-icon.eot?367512');
  src:  url('fonts/yona-icon.eot?367512#iefix') format('embedded-opentype'),
    url('fonts/yona-icon.ttf?367512') format('truetype'),
    url('fonts/yona-icon.woff?367512') format('woff'),
    url('fonts/yona-icon.svg?367512#yona-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="yi-"], [class*=" yi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'yona-icons' !important;
  font-style: normal;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;


  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  @each $color-name, $color-value in $theme-colors {
    &.yi-clr-#{$color-name} {
      color: $color-value;
    }
  }
  
  @for $i from 1 through 64 {
    &.yi-sz-#{$i * 2}{
      font-size: #{$i * 2}px;
    }
  }
}


.yi-account:before {
  content: "\e900";
}
.yi-add:before {
  content: "\e901";
}
.yi-arrow-down:before {
  content: "\e902";
}
.yi-arrow-forward:before {
  content: "\e903";
}
.yi-arrow-goto:before {
  content: "\e904";
}
.yi-check:before {
  content: "\e905";
}
.yi-close:before {
  content: "\e906";
}
.yi-code:before {
  content: "\e907";
}
.yi-delete:before {
  content: "\e908";
}
.yi-download:before {
  content: "\e909";
}
.yi-drag:before {
  content: "\e90a";
}
.yi-dropdown:before {
  content: "\e90b";
}
.yi-dropup:before {
  content: "\e90c";
}
.yi-edit:before {
  content: "\e90d";
}
.yi-filter:before {
  content: "\e90e";
}
.yi-home:before {
  content: "\e90f";
}
.yi-logout:before {
  content: "\e910";
}
.yi-refresh:before {
  content: "\e911";
}
.yi-remove:before {
  content: "\e912";
}
.yi-search:before {
  content: "\e913";
}
.yi-settings:before {
  content: "\e914";
}
.yi-upload:before {
  content: "\e915";
}
.yi-visibility:before {
  content: "\e916";
}
.yi-warning:before {
  content: "\e917";
}
.yi-width:before {
  content: "\e918";
}
