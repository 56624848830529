$h1-font-size: "47.78px";
$h2-font-size: "38.81px";
$h3-font-size: "33.18px";
$h4-font-size: "27.65px";
$h5-font-size: "23.04px";

$headings-font-family: "Poppins";


$font-family-sans-serif: "Poppins", "Roboto";


h1 {
  font-family: "Poppins SemiBold";
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: 1.911px;
}

h2 {
  font-family: "Poppins SemiBold";
  font-style: normal;
  font-weight: 600;
  line-height: 56px; 
}

h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  text-transform: uppercase;
}

h4 {
  font-family: "Poppins SemiBold";
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  text-transform: uppercase;
}

h5 {
  font-family: "Poppins SemiBold";
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-transform: uppercase;
}

p {
  //default: m size
  color: $text;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;

  &.p-lg {
    font-size: 19.2px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; 
  }

  &.p-md {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &.p-sm {
    font-size: 13.33px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &.p-xs {
    font-size: 11.11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

label {
  //default: m size
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  &.lable-lg {
    font-size: 19.2px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  &.label-md {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &.label-sm {
    font-size: 13.33px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
  }

  &.label-xs {
    font-size: 11.11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

a {
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-block;
}